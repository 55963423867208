@import "./variable";
@import "../variables.scss";

.bottom-nav {
  width: 100%;
  background-color: #000;
  text-align: center;
}

.bottom ul li {
  border-left: 1px solid;
  padding-left: 16px;
  padding-right: 16px;
}

.bottom ul li a {
  line-height: 1;
  color: #666;
  font-family: ProximaNova;
  font-size: 12px;
}

.bottom ul li a:hover {
  color: #fff;
}

.bottom .remove-border {
  border-left: none;
}

.bottom .last-nav {
  padding-right: 0;
}

.footer {
  padding: 1rem 90px 0 90px;
  background-color: #000;
  width: 100%;
  height: auto;

}

.footer-part-2 {
  margin: 24px 0 13px 0;
  text-align: right;
  color: #ffffff;
  line-height: 0;
  text-align: right;
  font-size: 14px;

  p {
    padding: 0 !important;
    margin-top: 5.4rem !important;
  }
}

.footer-part-1 {
  margin-right: 10px;
  text-align: left;

  .footer_image {
    width: 65px;
    height: 64px;
    margin: 19px 30px 19px 0;
    cursor: pointer;
    background-color: #fff;
    border-radius: 8px;
    padding: 5px;
  }

  .footer_image1 {

    height: 38px;
    margin: 34px 10px 32px 0;

    cursor: pointer;
  }

  .footer_image2 {

    height: 38px;
    /* margin-bottom: 32px; */
    cursor: pointer;
  }
}

.bottom-button a {
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid;
  color: #ffffff;
  font-size: 12 px;
}

.reach {
  background-color: #707070;
  position: relative;
  height: auto;
  padding: 48px 130px;
}

.reach p {
  margin: 0;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.25;
  font-family: Helvetica;
}

.reach_break {
  padding-bottom: 20px;
}

.reach h2 {
  font-size: 44px;
  font-family: "Helvetica";
  line-height: 1.18;
  font-weight: bold;
  color: #fff;
  margin: 0;

  &::after {
    @include after;
    margin: 5px 0 10px 0;
    height: 9px;
  }
}

.information {
  background-color: #fff;
  width: 570px;
  position: absolute;
  bottom: 48px;
  border: solid 1px #f7f7f7;
  right: 130px;
  padding: 30px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--white);

  .err {
    font-size: 14px;
    color: #ff0000cb;
    margin: 5px 0 !important;
  }

  .same-btn {
    line-height: 1.33;
    font-family: "Nunito", sans-serif;
    font-weight: bold;
    font-size: 15px;
    color: #000;
    margin: 0;
  }

  /*  .userName {
    display: flex;
  }

  .email {
    padding: 1rem;
    width: 50%;
    border-radius: 5px;
  } */
  textarea {
    max-height: 20vh;
  }


  .reach-Submit {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .reach-Submit p {
    color: #9b9b9b;
    margin-top: 0.5rem;
    margin-bottom: 0 !important;
    font-family: Helvetica;
    font-size: 14px;
    margin-right: 20px;
  }
}