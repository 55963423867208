@import "/src/Sass/variables.scss";
@import "/src/Sass/index.scss";
header {
  width: 100% !important;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);

  .bars,
  .close {
    display: none !important;
  }
  .logo {
    display: none !important;

    @include tablet {
      position: absolute !important;
      display: block !important;
      z-index: 1;
      left: 30px;
      padding: 6px 0;
    }
  }

  nav {
    font-family: "Lato" !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 0 60px;
    z-index: 100;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);

    .nav-brand {
      padding: 8px 0;
    }

    .btn-group {
      display: flex;
      align-self: center;
      align-items: center;
      @include tablet {
        flex-direction: column;
      }

      .dropdown-btn {
        width: 10em;
        height: 40px;
        font-size: 15px !important;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        border: 1px solid black;
        cursor: pointer;

        &:hover {
          border: transparent;
          background: $hover-color;
        }
        .text {
          padding: 0 10px;
          font-size: 15px !important;
          font-weight: bold;
        }

        .dropdown-menu {
          position: absolute !important;
          top: 50%;
          left: -170%;
          background: #fff;
          height: auto;
          width: 260px;
          list-style: none;
          box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
          border-radius: 0%;
          .item-name {
            cursor: pointer;
            width: 100%;
            padding: 20px 30px !important;
            font-weight: bold;
            .user,
            .history, .logout {
              font-size: 18px;
              margin-right: 13.3px;
              font-weight: 400 !important;
          
            }
            &:hover {
              background: #fafafa;
              text-decoration: underline;
              .icon {
                text-decoration: none;
              }
            }
          }

          &::after {
            content: "";
            width: 100%;
            height: 5px;
            bottom: 0;
            background: $hover-color;
            display: block;
          }
        }
      }
      .user {
        font-size: 18px;
      }

      .bell {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px !important;
        margin: 0 30px;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background-color: #fff;
        border: 1px solid black;
        cursor: pointer;

        &:hover {
          border: transparent;
          background: $hover-color;
        }
        @include tablet {
          margin-bottom: 20px !important;
        }
      }
    }

    .menu {
      border: none !important;
      font-family: "Lato" !important;
      margin-top: 25px !important;
      margin-left: 20% !important;

      .active {
        font-size: 15px !important;
        border-bottom-width: 4px !important ;
        @include tablet {
          border-bottom-color: #fff !important;
        }
      }

      .item {
        padding: 0 5px 31px !important;
        font-size: 15px !important;
        margin: 0 !important;
        padding: 0 25 !important;
        display: inline-block;
        border-bottom-width: 4px !important;
        &::after {
          border: 4px !important;
        }
      }
    }

    .action-group {
      display: inline-flex;
      .btn-apply,
      .btn-login {
        width: 130px;
        background-color: #fff;
        border-radius: 20px;
        height: 38px;
        font-weight: bold;
        font-size: 15px;
        cursor: pointer;
        border: 1px solid black;

        &:hover {
          background: $hover-color;
          border: transparent;
        }
      }
      .btn-login {
        margin-right: 30px;
      }
    }
  }

  //Manage Navigation
  .manage-nav {
    display: flex;
    height: 70px;
    align-items: center;
    background-color: #fff;
    padding: 0px 40px 0px 20px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);

    .input {
      margin-right: auto;
      margin-left: 50px;
      width: 350px;
    }
    .btn-group {
      display: flex;
      align-self: center;
      align-items: center;

      .dropdown-btn {
        width: 10em;
        height: 40px;
        font-size: 15px !important;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        border: 1px solid black;
        cursor: pointer;

        @media screen and (max-width: 580px) {
          width: 50%;
        }

        &:hover {
          border: transparent;
          background: $hover-color;
        }
        .text {
          padding: 0 10px;
          font-size: 15px !important;
          font-weight: bold;
        }

        .dropdown-menu {
          position: absolute !important;
          top: 120%;
          left: -170%;
          background: #fff;
          height: 180px;
          width: 260px;
          list-style: none;
          box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
          .item-name {
            cursor: pointer;
            width: 100%;
            padding: 20px 30px !important;
            font-weight: bold;

            .user,
            .history, .logout {
              font-size: 18px;
              margin-right: 15px;
              font-weight: 400 !important;
            }
            &:hover {
              background: #fafafa;
              text-decoration: underline;
              .icon {
                text-decoration: none;
              }
            }
          }

          &::after {
            content: "";
            width: 100%;
            height: 5px;
            background: $hover-color;
            display: block;
          }
        }
      }
      .user {
        font-size: 18px;
      }

      .bell {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px !important;
        margin: 0 30px;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background-color: #fff;
        border: 1px solid black;
        cursor: pointer;
        &:hover {
          border: transparent;
          background-image: linear-gradient(
            to right top,
            #c7a006,
            #ceae0d,
            #d5bc14,
            #dcca1c,
            #e1d925,
            #e1d925,
            #e1d925,
            #e1d925,
            #dcca1c,
            #d5bc14,
            #ceae0d,
            #c7a006
          );
        }
      }
    }
  } 
}

//Sidebar

.sidebar {
  background-color: #fff !important;
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 4px 0 10px 0 rgba(180, 180, 180, 0.1);
  margin: 20px 0;
  position: sticky;

 
  .item {
    cursor: pointer;
    img {
      width: 26px !important;
      margin: 20px;
      font-size: 22px !important;
      color: #d5d5d5;
      filter: opacity(20%);
    }
    &:hover {
      width: 115%;
      display: flex;
      justify-content: center;
      background: $hover-color;

      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .active {
    width: 115%;
    display: flex;
    justify-content: center;
    background: $hover-color;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    
    img {
      filter: none;
    }
  }
}

//toggleble Navigation Bar
header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 101;
  @include tablet {
    width: 100%;
    height: 70px;
    background: #fff !important;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  }

  .bars,
  .close {
    @include tablet {
      display: block !important;
      position: relative;
      cursor: pointer;
      font-size: 24px !important;
      top: 25px;
      left: 89%;
    }
  }

  nav {
    @include tablet {
      display: none !important;
      position: absolute;
      top: 0;
      left: 0;
      width: 60%;
      height: 100vh;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 20px 30px 10px 0px !important;
      background: #333333;
      z-index: 2;
    }
    .nav-brand {
      @include tablet {
        width: 80px;
        height: 80px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        background: #fff !important;
        border-radius: 50%;
      }
    }
    .close {
      @include tablet {
        display: block !important;
        position: absolute;
        top: 2%;
        left: 85%;
        font-size: 24px;
        color: #d5d5d5 !important;
        cursor: pointer;
      }
    }
    .menu {
      @include tablet {
        flex-direction: column;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 30px !important;
      }
      .item {
        @include tablet {
          color: #fff !important;
          padding: 30px 0 10px !important;
        }
      }
    }
  }
}
