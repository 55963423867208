@import "./variable";
@mixin verify_para {
  font-family: Open Sans;
  line-height: 1.36;
  font-size: 14px;
  text-align: left;
  color: #000;
  font-weight: normal;
}
@mixin verify_top {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  color: #000;
}

.accept_heading {
  text-align: center;
  @include verify_top;
}

.modal{
  padding: 8px !important;
  position: relative;
 left: auto;
 right: auto;
 top: auto;
 bottom: auto;
  height: auto;
  
}

.modal .header {
  &::after {
    content: none;
  }
}
.modal_p {
  @include verify_para;
  padding: 12px 0 21px 0;
  text-align: center;
  margin: 0;
}

.modal_img {
  margin: 0px auto;
  width: 50px;
  height: 50px;
  border: solid 1px #0cbe0c;
  background-color: rgba(12, 190, 12, 0.15);
  border-radius: 50%;
  margin-top: 20px;
}
.modal_img2 {
  margin: 0px auto;
  width: 50px;
  height: 50px;
  border: solid 1px #fa6363;
  background-color: rgba(12, 190, 12, 0.15);
  border-radius: 50%;
  margin-top: 20px;
}

.modal_img .icon1 {
  padding: 10px;
}
.accept_bottom {
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 5px;
    z-index: -1;

    border-radius: inherit;
    background: $gradient;
  }
  .same-btn {
    margin: 0 10px 40px 10px;
  }
}

/* reject screen */

.modal_img2 {
  margin: 0px auto;
  width: 50px;
  height: 50px;
  border: solid 1px red;
  background-color: #ffe9e9;
  border-radius: 50%;
  margin-top: 20px;
}

.modal_img2 .icon1 {
  padding: 10px 13.2px;
}



.documents {
  padding: 20px 30px 42px 30px;
  text-align: left;
  border-top: solid 1px #e6e6e6;

  .doc_inner {
    display: flex;
  }
  h6 {
    @include verify_top;
    color: #9d9494;
    font-weight: normal;
    margin-bottom: 5px;
  }
  p {
    font-size: 16px;
    line-height: 1.38;
    font-family: Open Sans;
    margin: 10px auto auto 12.4px;
  }
  img {
    width: 14.1px;
    height: 14.1px;
    margin-top: 1.1rem;
  }
}

.reason {
  @include verify_para;
  color: #9d9494;
}


.verification_form {
  padding: 1px 40px;
  margin-bottom: 18px;
}

/* verification scss */
.verification_content{
  padding: 32px 30px 14px 30px !important;
  height: auto;

  .grid .column{
    padding-top: 0px !important;
    padding-bottom: 20px !important;
    height: auto;
  }
}
.verify{  
  h6 {
    @include verify_para;
    font-weight: normal;
    color: #9d9494;
    margin-bottom: 5px;
  }
  p {
    @include verify_para;
  }
}


/* FAQ component */
.faq_content {
  border: none !important;
  padding: 10px 40px 40px 40px;
  .faq_inner {
    display: flex;
  }
  p {
    @include form_para;
    color: #9d9494;
    width: 82px;
  }
  td {
    border: none !important;
  }
  input {
    width: 34vw;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #e9e9e9;
    padding-left: 15px;
    margin: 20px 0 0 16px;
  }
  .field .selection {
    margin-left: 15px;
    width: 391px;
  }
}

/* service_modal */
.ui.modal > .content {
  padding: 0 !important;
}

.services_form {
  padding: 20px 41px 40px 41px;
  .field> label{
    font-size: 14px !important;
    font-weight: bold;
    line-height: 1.21;
  }
}
