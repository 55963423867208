@import './variable';


.request_wrapper{
    max-height: 173px !important;
    
}
.Request_bg{
    min-height: 91px;
        

}
.request3{
    box-shadow: 4px 0 10px 0 rgb(180 180 180 / 10%);
    background: #fff;
    width: 100%;
    padding: 15px;
    border-bottom: solid 1px #e6e6e6;
    p{
        @include main_heading;  
        font-weight: 500;
        width: 70%;
    }
    .para6{
        @include para7;
        
    }

    
}
