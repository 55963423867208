@import '/src/Sass/variables.scss';

//Status Chip
.In-process, .Success, .Pending{
    font-size: 14px !important;
    font-family: 'Lato' !important;
    border-radius: 20px !important;
    padding: 4px 12px !important;
    text-align: center;        
}
.In-process{
        color: #4ca0dd !important;
        background-color: rgba(76,160,221,0.15) !important;
}
.Success{
    color: #0cbe0c !important; 
    background-color: rgba(#0cbe0c, 0.15) !important;
}
.Pending{
     color: #d1a426 !important;
     background-color: rgba(#d1a426, 0.15) !important;
}
.Complete{
    color: #0cbe0c !important; 
    background-color: rgba(#0cbe0c, 0.15) !important;
}
.Completed{
    color: #0cbe0c !important; 
    background-color: rgba(#0cbe0c, 0.15) !important;
}
.Failed{
    color: red !important;
    background-color: rgba(red, 0.15) !important;
}
.Rejected{
    color: red !important;
    background-color: rgba(red, 0.15) !important;
    width: 100%;
    text-align: center;
    position: relative;
    bottom: 1rem !important;
 
}
.Accepted{
    color: #0cbe0c !important; 
    background-color: rgba(#0cbe0c, 0.15) !important;
}
//Card 
.company-card{
    background-color: #fff;
    border: 1px solid #e6e6e6;
    margin-right: auto;
    margin-left:30px;
    @include tablet{
        margin: 50px 10px !important;
    }
   
    &:after{
        width: 100%;
        display: block;
        content: '';
        height: 5px;
        background: linear-gradient(to right, #c7a006, #e7ed32 50%, #c7a006)  !important;
    }
    
.card-heading{
    height: 25vh;
    align-items: center;
    padding: 15px 0 15px 20px;
    border-bottom: 1px solid #e6e6e6;

    h5{
        margin-top: 10px;
        font-size: 16px !important;
        font-family: 'Lato' !important;
    }
        
}

.card-content{
    padding:10px 20px; 

 table{
    td{
        color: #9d9494 !important;    
        font-size: 14px;
        padding-right: 20px;
        padding-bottom: 20px;
    
        label{
            color:#000;
        }
    }
}
    .total{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;
        .fees{
            font-size: 14px;
            font-weight: normal;
            font-family: 'Open Sans' !important;
        }
        label{
            font-size: 20px;
            font-weight: bold;
            font-family: 'Lato';
             }
         }
    }
}



//Button Bar
.button-group{
    width: 100%;
    height: 70px  !important;
    display: flex !important;
    justify-content: space-between;
    padding: 0 50px !important;
    align-items: center;
    margin-top: 10% !important;
    padding: 15px 0 0;
    background-color: #fff;
    box-shadow: 0 -4px 15px 0 rgba(172, 172, 172, 0.1) !important;

    .button{
        width: 140px;
        height: 40px;
        background-color: #fff !important;
        border-radius: 20px !important;
        border: $simple-border;
        display: flex !important;
        color:$black !important;
        align-items: center;
        justify-content: center;

        &:hover{
            border: 1px solid transparent !important;
            background: $hover-color;
        }
    }
}


//Stepper 
.stepper-container{
    border:1px solid $border-color;
    background-color: $white;
    
    .stepper{
            padding: 20px 20px 0;
        @include tablet{
            flex-direction: column; 
            align-items: stretch;
            padding:0px;
            padding: 20px 20px 0;
        }
        .active{
            background: $hover-color;
            .content{
                .title, .description{
                    color:black !important;
                         }
                    }
                    &::after{
                        background:linear-gradient(252deg, #c7a006 50%, #c7a006) !important;
                        border-top: none !important;
                    }
             }
        .step{
            @include tablet{
                border-bottom: 1px solid $border-color !important;
            }
            font-family: 'Lato';
            text-align: center !important;
             .content{

             .title{
                 color:$disabled-color;
             }
             .description{
                 color: $disabled-color;
                 font-size: 14px;
                 font-weight: bold;
             }
            }
        }
    }
}