@import "./variable";

.appoint2 {
  display: flex;
  width: 350px;
  height: 114px;
  border: solid 1px #e6e6e6;
  padding: 11px 11px;
  margin-bottom: 60px;
  .date {
    border-radius: 5px;
    background-color: #f7f7f7;
    display: grid;
    justify-items: center;
    width: 77px;
    margin-right: 10px;
    height: 70px;
    
  }
  .jan {
    font-size: 12px;
    line-height: 1.25;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8.7px;
  }
  .number {
    font-size: 30px;
    line-height: 1.2;
    font-size: 30px;
    margin: 9.3px 21px 0 21px;
    font-weight: bold;
  }
  .nov {
    font-size: 12px;
    line-height: 1.25;
    font-weight: 500;
    display: flex;
    padding: 9px 18px;
    color: #9d9494;
  }
  .number2 {
    font-size: 30px;
    line-height: 1.2;
    font-size: 30px;
    font-weight: bold;
    margin: 9px 21px;
    color: #9d9494;
  }

  .first_info {
    background-color: red;
    padding: 2px 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.25;
    color: #9d9494;
    background: #f7f7f7;
    border-radius: 20px;
  }
  .done_info {
    background: $primary-color;
    background-image: $gradient;
    padding: 2px 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.25;
    color: #000000;
    border-radius: 20px;
  }
  p {
    font-size: 14px;
    line-height: 1.21;
    font-weight: 500;
    color: #000000;
    text-align: left;
    padding-right: 1rem;
    margin-top: 12px;
  }
  .minute {
    color: #9d9494;
    line-height: 1.21;
    font-size: 14px;
    font-weight: 500;
  }
}
.view_segment {
  margin-bottom: 60px;
}
.vertical_step1 {
  display: flex;
  width: 100%;
  height: auto;
  padding: 30px;
  border: solid 1px #e6e6e6;
  margin-bottom: 60px;
  p {
    color: #9d9494;
    font-size: 18px;
    line-height: 1.22;
    text-align: left;
    font-weight: 500;
    margin-left: 29.6px;
    width: auto;
  }
  img {
    width: 40.1px;
    filter: saturate(0.5);
    height: 35px;
    background-color: #a4d166;
  }
}
.vertical_step4_inner1 {
  display: inline-flex;
}
.details_wrapper_inner {
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
  border-top: none;
  
}

.view_detail_wrapper {
  background: #fcfcfc;
  height: 150vh;
  margin-top: 62px;
  tr{
    box-shadow: none !important;
    
  }
  h2 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.22;
  }
  h2:after {
    content: "";
    position: absolute;
    top: 0rem;
    left: 0;
    height: 4px;
    border-radius: inherit;
    background: red;
    margin: 14px;
  }
  td{
    padding: 0 !important;
    
  }
  td h6 {
    font-size: 14px;
    color: #9d9494;
    line-height: 1.21;
    font-weight: 500;
    
  }
  td p {
    @include main_heading;
    margin-top: 5px;
    font-weight: 500;
    height: 30px;
  }
  table{
    padding: 40px 30px;
  }
}

.details_3_outer {
  margin-bottom: 60px;
  padding: 20px 0 22px 31.4px;
  border: solid 1px #e6e6e6;
  width: 100%;
  
  & a {
    text-decoration: underline;
    margin-left: 30px;
    font-size: 16px;
    font-family: Open Sans;
    line-height: 1.38;
    cursor: pointer;
  }

  .details_3 {
    display: flex;
    height: auto;
    margin-bottom: 10px;
  }
  p {
    font-size: 16px;
    font-family: Open Sans;
    line-height: 1.38;
    margin: 0 !important;
    text-transform: capitalize;
    justify-content: flex-start;
  }
  img {
    width: 14.1px;
    height: 14.1px;
    margin-top: 5px;
  }
  .details_3{

    height: 3rem;
  }
  .same-btn{
    height: 32px;
    position: absolute;
    right: 5em;
  }


}

.vertical_step4 {
  width: 100%;
  height: auto;
  border: solid 1px #e6e6e6;
  padding: 20px 30px;
  margin-bottom: 60px;
}

.vertical_step4_inner1 {
  h6 {
    color: #9d9494;
    line-height: 1.42;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: normal;
  }
  p {
    font-family: Open Sans;
    font-size: 14px;
    line-height: 1.36;
    color: #000;
    margin-right: 1.5rem;
  }
}
.vertical_step4_inner2 {
  h6 {
    color: #9d9494;
    line-height: 1.42;
    font-family: Open Sans;
    font-size: 12px;
    margin-top: 15px;
  }
  p {
    font-family: Open Sans;
    font-size: 14px;
    line-height: 1.36;
    color: #000;
  }
}

.view_segment3 {
  width: 100%;
  border: solid 1px #e6e6e6;
  padding: 20px 30px;
}
.company_formation1 {
  h5 {
    line-height: 1.19;
    font-size: 16px;
    font-weight: bold;
  }
}

.company_formation2 {
  td h6 {
    font-size: 12px;
    color: #9d9494;
    font-weight: normal;
    line-height: 1.42;
    font-family: Open Sans;
  }
  td p {
    font-size: 14px;
    color: #000;
    font-weight: normal;
    line-height: 1.36;
    font-family: Open Sans;
  }
  td .total_amoint_1 {
    font-size: 14px;
    line-height: 1.36;
    color: #000;
    font-family: Open Sans;
    margin: 0;
  }
  .total_amoint_2 {
    line-height: 1.22;
    font-size: 18px;
    font-weight: bold;
    color: #000;
    font-family: Lato;
  }
  table{
    padding: 0 !important;
  }
}

/* //left stepper// */
.vertical.steps .step {
    margin-bottom: 8.75rem !important;     
    border: none !important;
} 
.outer_view{
  display: flex !important;
  width: 100% !important;
  .outerStep{
    display: flex;
    width: 25%;
    margin: 1rem 2rem !important;
    @media only screen and (max-width: 600px) {
      display: none;
     
    }
  }
}
