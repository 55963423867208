//colors
$border-color: #e6e6e6;
$white:#fff;
$disabled-color: #9d9494;
$hover-color: linear-gradient(252deg, #c7a006, #e7ed32 50%, #c7a006)  !important;
$black: #000;
$white: #fff;
$red:#e5181a;
//borders
$simple-border: solid 1px #000000 !important;


//Flex
@mixin flex{
    display: flex !important;
    justify-content:  center;
    align-items: center;
}

//Media Queries 
@mixin mobile{
    @media only screen and (min-width:320px) and (max-width:480px){
        @content;
    }
}

@mixin tablet{
    @media only screen and (max-width:768px){
        @content;
    }
}

@mixin laptop{
    @media only screen and (max-width:1200)
    {
        @content;
    }
}


// Book Appointment Calender
.react-calendar {
    max-width: 100%;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
    padding: 10px;
    background: white;
    font-family: 'Gotham';
    &--doubleView {
      width: 700px;
  
      .react-calendar__viewContainer {
        display: flex;
  
        > * {
          width: 50%;
          margin: .5em;
        }
      }
    }
  
    &, & *, & *:before, & *:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-family: 'Gotham';
    }
  
    button {
      margin: 0;
      border: 0;
      outline: none;
  
      &:enabled {
        &:hover {
          cursor: pointer;
        }
      }
    }
  
    &__navigation {
        
      &__prev2-button, &__next2-button
      {
          display: none !important;
      }
      &__prev-button, &__next-button{
          font-size:18px !important;
      }
      margin-bottom: 1em;
  
      button {
        min-width: 44px;
        color:#b2b2b2 ;
        background: none;
  
        &:enabled {
          &:hover{
              color: #000;
          }
        }
  
        &[disabled] {
          background-color: rgb(240, 240, 240);
        }
      }
    }
  
    &__month-view {
      &__weekdays {
        text-align: center;
        text-transform: uppercase;
        font-size: .87em;
        color:#b2b2b2;
        font-family: 'Gotham';
  
        &__weekday {
          padding: .5em;
        }
      }
  
      &__weekNumbers {
        font-weight: bold;
        display: none !important; 
      }
  
      &__days {
        &__day {
          &--weekend {
            color:#000;
          }
  
          &--neighboringMonth {
              visibility: hidden;
          }
        }
      }
    }
  
    &__year-view,
    &__decade-view,
    &__century-view {
        .react-calendar__tile {
        }
      }
    
      &__tile {
        max-width: 100%;
        text-align: center;
        margin:10px 0px!important;
        padding: 2px 0;
        background: none;
    
        &:disabled {
          background-color: rgb(240, 240, 240);
        }
    
        &:enabled {
          &:hover {
            background: #000;
            color:#fff;
            font-weight: 500;
            border-radius:20px;
          }
        }
    
        &--now {
          background: black;
          color: #fff;
          border-radius: 40px;
    
          &:enabled {
            &:hover{
              background: $hover-color;
              color:#000;
              font-weight: 500;
            }
          }
        }
    
        &--hasActive {
            background:#000;
          &:enabled {
            &:hover, &:focus {
              background: #fff;
            }
          }
        }
    
        &--active {  
          // color: #000;
    
          &:enabled {
            &:hover, &:focus {
            }
          }
        }
      }
      &--selectRange {
        .react-calendar__tile {
          &--hover {
              background: #fafafa;
          }
        }
      }
    }