@import './variable';
@import '../variables.scss';
.appoint_wrapper{
    height: 273px;
    overflow: hidden;
    overflow-y: scroll;
}
.border_appointment{
    background-color:  #ffffff;
    width: 100%;
    padding: 15px;
    border-bottom: solid 1px #e6e6e6;
    box-shadow: 4px 0 10px 0 rgb(180 180 180 / 10%);
    margin-top: 10px;
}

.appoint_card{
    display: flex;
    width: 100%;
   
    
& p{
       background-color: #ffffff;
        @include main_heading;
        font-weight: normal;
        margin-right: 32px;
        width: 80%;     
    }
    


.action-icon{
    display: flex;
    margin-left: auto !important;    
    margin-right: 0;
    .check, .close{
        border: 1px solid black;
        width: 34px;
        height: 34px;
        font-size:16px;
        border-radius: 50%;   
        margin: 15px !important;
        margin-right: 0 !important;
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;

        &:hover{
            background: $gradient;
            border:transparent;
        }
    }
}

& img{
    
    width: 34px;
     margin: 15px 0 15px 20px;
    height: 34px;
}
img:hover{
    filter: invert(1);
}

}
