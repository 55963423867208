@import '/src/Sass/variables.scss';

.toggle{
    display: none;
    @include tablet{
    background: #fff;
    padding: 20px 10px;
    z-index: 2;
    display: block;
    width:300px;
    height: 100vh;
    position: absolute;
    top: 0;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);

    @media only screen and (max-width:320px)
    {
        width: 200px;
    }
    }
    .nav-brand{
        display: flex;
        justify-content: center;
    }
    .close{
        display: block !important;
        position: absolute;
        top:2%;
        cursor: pointer;
        left: 85%;
        font-size: 20px;
        font-weight: 400;
    }

    .btn-group{
        display: flex;
        flex-direction: column;
        align-items: center;

        .bell{
            display: flex;
            justify-content: center;
            align-items: center;
            margin:10px !important;
            font-size: 22px;
            border-radius: 20px;
            padding: 20px;
            background:#fff;
            border: 1px solid black;
            &:hover{
                cursor: pointer;
                background:$hover-color;
                border:1px solid transparent;
                    }
                }

.dropdown-btn{
    width: 10em;
    height: 40px;
    font-size: 15px !important;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0!important;
    border-radius: 20px;
    border: 1px solid black;
    cursor: pointer;

    &:hover{
        border:transparent;
        background: $hover-color;
    }
    .text{
        padding:0 10px;
        font-size: 15px !important;
        font-weight: bold;
        }
   
.dropdown-menu{
    position: absolute !important;
    top: 120%;
    left:-120% !important;
    background: #fff;
    height: 180px;
    width: 260px;
    list-style: none;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
        .item-name{
            cursor: pointer;
            width: 100%;
            padding: 20px 30px!important;
            font-weight: bold;
        
            .user, .history{
                font-size: 18px;
                margin-right: 15px;
                font-weight: 400 !important;
            }
                &:hover{
                background: #fafafa;
                text-decoration: underline;
                .icon{
                    text-decoration: none;
                    }
                }
             }
             
        &::after{
            content: '';
            width: 100%;
            height: 5px;
            background: $hover-color;
            display: block;
            }
        }
    }
    .user{
        font-size: 18px;
        } 
  }

    .action-group{
        display: flex;
        align-items: center;
        flex-direction: column;
        .btn-apply, .btn-login{        
        width: 130px;
        background-color: #fff;
        border-radius: 20px;
        height: 38px;
        font-weight: bold;
        font-size: 15px;
        margin: 10px 0!important;
        cursor: pointer;
        border: 1px solid black;
            
        &:hover{
            background:$hover-color;
            border:transparent;
        }
       }
    }

    .menu{
        border:none !important;
        font-family:  'Lato' !important;
        display: flex;
        margin: 1px 0 !important;
        flex-direction: column;
        margin: 20px 0 !important;
        align-items: center;

        .active{
        font-size: 15px !important;
        border-bottom-width: 4px !important ;
        border-bottom-color: #000 !important;      
        }
         .item{
            padding:0 0 10px !important;
            font-size: 15px !important;
            margin: 20px 0!important;
            border-bottom-width: 4px !important;
            &::after{
                border: 4px !important;
            }
          }
     }
}