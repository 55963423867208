@import '/src/Sass/index.scss';
@import '/src/Sass//variables.scss';
.manage-main{
    width: 100%;
    display: flex;   

    .breadcrumb{
        padding: 20px 30px;
        
        @include tablet{
            padding:20px 2vw;
        }
    }
.table-container{
    width: 100%;
}

.history-main{
    width: 100%;
    margin: 0 30px 0 30px;

    @include tablet{
        margin: 0;
    }
}
.manage-container{
    padding: 10px 40px 10px 30px; 
    
    @include tablet{
        padding:10px 20px 10px 2vw;
    }
    .manage_heading{
        width: 100%;
       
        & .same-btn{
            float: right;
            margin-bottom: 10px;
            width: 153px;
            height: 31px;
        }
        
    }
    h2{
        font-family: 'Lato' !important;
        font-size: 18px;
        
        @include tablet{
            padding: 0px 15px;
        }

        &:after{
            content: '';
            display: block;
            width: 120px;
            height: 4px;
            margin: 5px 0 10px;
            background: $hover-color;            
            clip-path: polygon(0 0, 87% 0, 100% 100%, 0% 100%);

        }
    }

    th{
        font-family: 'Lato' !important;
        color:$disabled-color !important;
        background-color: #fff !important;
        padding: 20px !important;
        font-weight: 500 !important;
    }
    td{
        padding: 20px !important;
        font-weight: 500;
        border: none !important;
        & img{
            cursor: pointer;
        }
    }
    .action{
        display: flex;
         @include tablet{
             flex-direction: column;
             }
        .check, .close{
            border: 1px solid black;
            width: 34px;
            height: 34px;
            border-radius: 50%;   
            margin: 10px 15px 10px 0;
            display: flex;
            cursor: pointer;
            justify-content: center;
            align-items: center;
            &:hover{
                background: $hover-color;
                border:transparent;
            }
        }
    }
    .view{
        color: #000;
        font-weight: bold;
        text-transform: uppercase;
        &:hover{
            text-decoration: underline;
        }
        a{    
        font-weight: bold;
        text-transform: uppercase !important;
        text-decoration: none !important;
        color:black !important;
        }
    }
    tr:nth-child(even)
    {
        background-color: rgba(157, 148, 148, 0.05);
    }
    .pagination-container{
    margin: 30px 0 !important;
    display: flex;
     justify-content: space-between;
     align-items: center;
     @include tablet{
         flex-direction: column;
         align-items: flex-start !important;
     }
    .page-name{
        display: block;
        font-weight: 500 !important;
        @include tablet{
            margin-left: 12px;
            margin-bottom:10px;
        }
    }
     .pagination{
         background-color: none;
         box-shadow: none;
         border:none;
        
         .item{
            background-color: #fcfcfc !important;
             font-weight:  bold !important;
             color: $disabled-color;
             &::before{
                 display: none !important;
             }
         }
        .active{
            background-color: transparent!important;
             &:after{
                 content:'';
                 position:absolute;
                 top: 75%;
                 right: 40%;
                 width: 8px;
                 height: 1px;
                 background-color: black;
             }
        }
        .next, .prev{
            color: #000 !important;
            margin-right: 4rem;
           &:after{
               content:'';
               position:absolute;
               top: 75%;
               right: 60%;
               width: 36px;
               height: 1px;
               background-color: black;
           }
        }
     }

 }   
}
}
.verify_btn{
      .same-btn{
          height: 30px;
          width: auto;
          padding: 0 15px;
      }
}