@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import "./variable";
@import "../variables.scss";

.about {
  padding-bottom: 52px;
  position: relative;
  background-color: #fff;
  min-height: 90vh;
  .about_bg {
    width: 100%;
    height: auto;
  }
  .about-content {
    max-width: 100%;
    padding: 0 10px;
    position: absolute;
    margin: auto;

    top: 38px;
    left: 0;
    bottom: 0;
    right: 0;

    text-align: center;
  }
  img {
    width: 185px;
    height: 182px;
  }
  p {
    @include contact_p;
    color: #9d9494;
    margin-bottom: 38px;
    margin-top: 20px;
    text-align: center;
  }
  h1 {
    padding-top: 52px;

    &::after {
      @include center_after;
    }
  }
}

.team {
  background-color: #fff;
  margin-top: 35%;
  @media only screen and (max-width: 1100px) {
  margin-top: 40%;
  
  }
.about_container{
  
}
  h1 {
    &::after {
      @include after;
      height: 9px;
      margin-top: 10px;
    }
  }

  .team-name {
    h5 {
      font-size: 3rem;
      font-weight: 900;
      font-family: Lato;
      line-height: 1.22;
      text-align: left;
      margin: 0 !important;
    }
    h6 {
      font-size: 1.5rem;
      width: 50%;
      font-weight: 600;
      color: #9d9494;
    }
    p {
      font-family: Open Sans;
      color: #9d9494;
      line-height: 1.36;
      font-size: 14px;
      text-align: left;
      /*   margin: 5px 0 20px 7.27rem; */
      text-align: right;
    }
  }
  .about-card {
    position: relative;

    border: solid 1px #e6e6e6;
    background-color: #ffffff;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    width: auto;
    max-height: auto;
  }
  .team_text {
    text-align: left !important;
    padding: 2rem;
    height: auto;
    @media only screen and (max-width: 600px) {
      padding: 5px;
      h5 {
        font-size: 20px;
      }
      h6 {
        font-size: 16px;
        width: 100%;
      }
      p {
        font-size: 14px !important;
        margin-top: 15px !important;
      }
    }
  }
  .team_column {
    display: inline-flex;
    height: auto;
  }
  .team_image {
    width: auto !important;

    height: 50vh;
    img {
      object-fit: cover;
      width: 28.2vw;
      height: 50vh;
      overflow: hidden !important;
    }
  }
  .space1 {
    height: auto;
    margin-top: 6rem;
  }
  .space2 {
    margin-bottom: 5rem;
    height: auto;
  }
  .team-image {
    position: absolute;
    top: -3rem;
    left: -1.6rem;

    & img {
      width: 120px;
      height: 120px;
    }
  }

  .team-content p {
    font-family: Open Sans;
    font-size: 16px;
    line-height: 1.38;
    margin-top: 2rem;
  }
}

.contact {
  text-align: center;
  height: 50vh;

  position: relative;
  .information {
    background-color: red !important;
  }
  img {
    object-fit: cover;
    height: 40vh;
    width: 100%;
    filter: brightness(45%);
  }
  .contact_content {
    position: absolute;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    & h1 {
      color: #fff;
      margin-top: 10rem;
      font-family: "Charmonman", cursive;
      @include banner_heading;
      font-size: 8vh;
      &::after {
        @include center_after;
        margin-bottom: 20px;
      }
    }
    & h2 {
      color: #fff;
      font-weight: 500;
    }
    & p {
      color: #fff;
      text-align: center;
      @include contact_p;
    }
  }
}

.address {
  margin: 76px 0 116px 0;
  padding: 0 5rem;
  iframe {
    width: 100%;
    border: transparent;
    height: 349px;
  }
  .information {
    min-height: 346px;
    position: static;
    width: 100%;
  }
  .contact-details-1 {
    display: inline-flex;
    width: 100%;
    height: 130px;
    border: solid 1px #e6e6e6;

    margin-bottom: 20px;
    padding: 30px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
 
  }
  .contact-details-1 img {
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
  }
  .contact-details {
    display: inline-flex;
    width: 100%;
    height: 89px;
    border: solid 1px #e6e6e6;
    margin-bottom: 20px;
    padding: 30px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .contact-details img {
    width: 48px;
    height: 48px;
  }

  .contact-details p {
    margin-left: 20px;
    @include para;
  }
  .contact-details-1 p {
    margin-left: 20px;
    @include para;
  }
}

/* company page scss */
.company_wrapper {
  background: #fff;
}

.company {
  text-align: left;
  height: auto;
  padding: 82px 0;
  h1 {
    @include banner_heading;
    &::after {
      @include after;
      margin-top: 10px;
      height: 9px;
    }
  }

  img {
    background-size: cover;
  }

  p {
    @include contact_p;
    color: #fff;
    margin-top: 20px;
    width: 70%;
  }
}

.company_plans {
  .hours-total {
    border-bottom: solid 1px #e6e6e6;
  }
  .hours {
    display: flex;
    align-items: center;
  }
  .form-btn {
    width: 88%;
    height: 40px;
    margin: 15px 20px 20px 20px;
  }
  table {
    border: none !important;
    padding: 20px 0 36px 0.75rem !important;
    margin: 0 !important;
  }
  td {
    border: none !important;
    line-height: 1.36 !important;
  }
  th {
    padding-left: 0.75rem;
    color: #9d9494;
    font-size: 14px;
    line-height: 1.36 !important;
  }
  .year{
    display: block;
  }
}
.overview {
  margin: 40px 0 136px 0;
  width: 100%;
  p {
    font-size: 18px;
    line-height: 1.33;
    color: #9d9494;
    font-family: Open Sans;
    text-align: left;
  }
  .golu{
    margin-right: 2rem !important;
    width: 30rem;
    max-width: 100%;

    
    
  }
  .testimonial {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }
  .testimonial .ui label{
    background-color: red;
  }
  .dropdown {
    
    min-height: 56px;
    padding: 15px 30px 17px 20px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    margin: 41px 0;
    & .icon {
      box-shadow: none !important;
      float: right !important;
      display: flex;
      margin-left: 1rem !important;
      align-items: flex-start !important;
    }
    & .menu {
      width: 400px;
    }
    .item, .text{
      font-size: 16px !important;
      font-weight: 600;
      color: rgb(0, 0, 0,0.7) !important;
    }
    &:hover{
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
    }
  }
  .testimonial img {
    width: 17px;
    height: 17px;
    margin-right: 11px;
    margin-top: 0px;
  }
  .testimonial p {
    font-size: 18px;
    line-height: 1.33;
    color: #9d9494;
    font-family: Open Sans;
  }
  h3 {
    height: 29px;
    line-height: 1.21;
    margin: 46px 0 20px 0;
    font-size: 24px;
  }
}

.crumb {
  text-decoration: none;
  position: absolute;
  left: 50px;
  color: #fff;
  z-index: 1;

  a {
    font-family: Open Sans;
    font-size: 12px;
    line-height: 0.42;
    font-weight: 600 !important;
  }

  .active {
    font-family: Open Sans;
    font-size: 12px;
    line-height: 1.42;
    font-weight: normal !important;
    color: #fff !important;
    text-decoration: none;
  }
  .icon {
    color: $disabled-color !important;
    font-size: 16px !important;
  }
}
.about_crumb{
  max-width: 100%;
}
.about_crumb .breadcrumb {
  text-decoration: none;
  position: absolute;
  left: 50px;
  color: #fff;
  z-index: 1;
  a {
    font-weight: 600;
  }
  .icon {
    color: $disabled-color !important;
    font-size: 16px !important;
  }

  .active {
    color: #000 !important;
  }
}

/* login pages sass */
.loginx {
  position: relative;
  width: 100%;
  height: 95vh !important;
  .ui.form .field > label {
    @include form_para;
  }

  .loginx img {
    background-size: cover;

    position: relative;

    width: 100%;
    z-index: -2;
  }
  .loginx_form {
    @include login_property;
  }
  .fgt1 {
    display: inline-block;
    padding-bottom: 30px;
    @include form_para;
  }
  .fgt2 {
    display: inline-block;
    padding: 30px 0 30px 0;
  }

  & a {
    @include form_para;
    text-decoration: underline;
  }

  .field {
    text-align: left;
  }
  .check {
    background-color: #f7f7f7;
    padding: 19px 20px 22px 20px;
  }
  h3 {
    line-height: 1.21;
    font-family: Lato;
    font-size: 28px;
    font-weight: bold;
    line-height: 1.21;
    margin-bottom: 16.4px !important;
  }
  p {
    font-size: 1px;
    font-weight: 900;
  }
}

/* login 2 sass */

.otp {
  display: inline-flex;
}
.loginx_form2 {
  @include login_property;
  padding: 40px 50px !important;
  height: auto;
  h4 {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
  }
  h3 {
    margin-bottom: 21px !important;
  }
}

.error {
  width: 100%;
  height: 20px;
  background-color: rgba(229, 24, 26, 0.1);
  border-radius: 4px;
  margin: 8px 0;
  p {
    font-size: 14px !important;
    font-weight: 500;
    padding: 0 10px;
    color: #9f3a38;

    /* color: #e5181a; */
  }
}

/* login form3  sass */
.loginx_form3 {
  @include login_property;
  height: 301px;
  .form-btn {
    margin-top: 30px !important;
  }
  .field {
    margin: 0 !important;
  }
  h3 {
    margin-bottom: 8px !important;
  }
}

/* Account screen sass */

.account {
  h4 {
    font-family: Lato;
    color: #000000;
    font-size: 18px;
    line-height: 1.22;
    font-weight: bold;
    margin: 0 auto;
    &::after {
      @include after;
      margin: 5px 0 10px 0;
    }
  }
}

.my_user {
  display: flex;
  padding: 20px;
}
.user_inner1 {
  position: relative;
  img {
    background-image: linear-gradient(
      to bottom,
      #c7a006,
      #d9cb1f,
      #e7ed32,
      #dacd20,
      #c7a006
    );
    padding: 3px;
    margin-right: 30px;
    width: 97px;
    height: 97px;
    border-radius: 50%;
  }
}
.edit_profile {
  position: absolute;
  top: 42.25%;
  right: 20.25%;

  justify-content: center !important;

  & .icon {
    font-size: 24px !important;
  }
}
.user_inner2 {
  h6 {
    color: #707070;
    line-height: 1.19;
    font-size: 16px;
    font-weight: 500;
  }
  p {
    line-height: 1.19;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    width: 125px;
    height: 19px;
  }
}
.user_inner3 {
  margin-left: 50px;
  h6 {
    color: #707070;
    line-height: 1.19;
    font-size: 16px;
    font-weight: 500;
  }

  p {
    line-height: 1.19;
    font-size: 16px;
    font-weight: 500;
  }
}

.account_carousel {
  background: linear-gradient(0.95turn, #010101, #505050, #010101);
  width: auto;
  height: 238.5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .carousel_p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.19;

    color: #fff;
  }
  .carousel_number {
    font-size: 48px;
    line-height: 1.19;
    font-weight: bold;
  }
}
.accept_bottom::after {
  content: none;
}

.user_heading {
  h4 {
    font-family: Lato;
    color: #000000;
    font-size: 18px;
    line-height: 1.22;
    font-weight: bold;
    margin: 0 auto;
    &::after {
      @include after;
      margin: 5px 0 10px 0;
    }
  }
}

.appoint {
  display: flex;
  border: solid 1px #e6e6e6;
  margin-bottom: 20px !important;
  height: 113px;
  padding: 11px;
  .date {
    border-radius: 5px;
    background-color: #f7f7f7;
    display: block;
    width: 77px;
    margin-right: 10px;
    height: 70px;
  }
  .jan {
    font-size: 12px;
    line-height: 1.25;
    font-weight: 500;
    display: flex;
    width: auto;
    display: flex;
    align-self: center;
    justify-content: center;
  }
  .number {
    font-size: 30px;
    line-height: 1.2;
    font-size: 30px;
    font-weight: bold;
    margin: 9px 21px;
  }
  .nov {
    font-size: 12px;
    line-height: 1.25;
    font-weight: 500;
    display: flex;
    padding: 9px 18px;
    color: #9d9494;
  }
  .number2 {
    font-size: 30px;
    line-height: 1.2;
    font-size: 30px;
    font-weight: bold;
    margin: 9px 21px;
    color: #9d9494;
  }

  .first_info {
    background-color: red;
    padding: 2px 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.25;
    color: #9d9494;
    background: #f7f7f7;
    border-radius: 20px;
  }
  .done_info {
    background: $primary-color;
    background-image: $gradient;
    padding: 2px 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.25;
    color: #000000;
    border-radius: 20px;
  }
  p {
    font-size: 14px;
    line-height: 1.21;
    font-weight: 500;
    color: #000000;
    text-align: left;
    padding: 14px 0 10px 0;
  }
  .minute {
    color: #9d9494;
    line-height: 1.21;
    font-size: 14px;
    font-weight: 500;
  }
}
.history {
  text-align: center;
}
.circle_btn {
  display: flex;
  justify-content: center;
}
.hello {
  color: red;
}
.status {
  background-color: #daf5da;
  padding: 1px 12px;
  color: #0cbe0c;
  border-radius: 20px;
}
.account-btn {
  font-family: Lato;
  width: 176px;
  height: 40px;
  padding: 11px 20px;
  border: 1px solid #000;
  background: #ffffff;
  object-fit: contain;
  border-radius: 20px;
  color: #000;
}
.account-btn:hover {
  background: rgb(199, 160, 6);
  background: linear-gradient(
    90deg,
    rgba(199, 160, 6, 1) 11%,
    rgba(231, 237, 50, 1) 51%,
    rgba(199, 160, 6, 1) 92%
  );
  color: #000;
  border: 1px solid #c7a006;
}

.account_table {
  text-align: left;
  margin-bottom: 31px;
  th {
    color: #9d9494 !important;
    background: #fff !important;
    font-weight: 500 !important;
    line-height: 1.21;
    // padding: 10px !important;
  }
  td {
    // padding: 20px !important;
    border: none !important;
  }
}
.account_wrapper {
  background: #fcfcfc;
  padding-top: 15px;
  height: 150vh;
  margin: 0 50px;
}
.account_breadcrumb {
  margin: 0 50px;
}

/* my journey */

.my_journey {
  overflow-x: hidden;
  
  h1 {
    text-align: center;

  }
  h2{
    font-size: 1.5rem;
  }
  .content_list_item {
    width: 7.75em;
    height: 7.75em;
    background: black;
    border-radius: 50%;
    display: grid;
    margin: 50px 0px;
    place-items: center;
    color: white !important;
  
    // display: inline;

    &::after {
      content: "";
      border: 2px solid black;
    
      height: 80%;
      position: absolute;
      // top: 87%;
      left: auto;
      right: auto;
      top: 10%;
      z-index: -5;
    }
  }
}
.odd_child,
.even_child {
  display: flex;
}
.odd_child_content {
  display: flex;
  align-items: center;
  margin-left: -1;

  &::before {
    content: "";
    border: 2px solid black;
    width: 40%;
    height: 2px;
  }
 
}

.even_child_content {
  display: flex;
  align-items: center;
  position: relative;
  left: -42vw;
  z-index: -1;
  &::after {
    content: "";
    border: 2px solid black;
    width: 40%;
    height: 2px;
  }
  img{
    object-fit: cover !important;
    margin-left: 10px;
  }
}

.flex_content {
  // margin-left: 1%;
  margin: 10px;
}

.my_journey {
  width: 100%;
  height: auto !important;
padding-top: 5%;
  background-color: #fff;
  margin-bottom: 15vh;
  .journey_tree {
    position: relative;

    transform: translateX(47.5%);

    img {
      object-fit: cover !important;
      border-radius: 50% !important;
      width: 160px !important;
      height: 160px;
      background: #000 !important;
    }
  }
  
  @media only screen and (max-width: 1400px) {
    .even_child_content {
      left: -45vw;
      &::after {
        min-width: 20%;
      }
  }
  .odd_child_content {
  
    &::before {

      width: 10%;
      height: 2px;
    }
  }
  


}
}

// .odd_year::after {
//   position: relative !important;
//   // top: -35px;
//   // left: 107px;
//   // left: 15px !important;
//   display: block !important;
//   content: "";
//   border: 1px solid black !important;
//   width: 100px !important;
// }

// content_list_item
/* //my journey/// */
// .flow_chart{

//   // h1 {
//     // &::after {
//     //   @include after;
//     //   height: 9px;
//     //   margin-top: 10px;
//     // }

//   // .content_list_item{
//     // width: 7.75em;
//     // height: 7.75em;
//     // background-color: 0;
//     // border-radius: 50%;
//     // display: grid;
//     // place-items: center
//   // }
//     ul.content_list {
//       list-style-type: none;
//     }
//     li {
//       width: 6em;
//       height: 6em;
//       border-radius: 50%;
//       border: 1px solid #c7a006;
//       text-align: center;
//       display: block;
//       // line-height: 2em;
//       // place-content: center;
//       margin: 2em 0;
//     );

//       p {
//         color: white;
//         font-weight: 600;
//         font-size: 1.4em;
//       }
//     }
// }
