@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.cdnfonts.com/css/gotham');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');


body{
    margin:0;
    padding: 0;
    font-family: 'Open Sans';
    box-sizing: border-box;
    background-color: #fcfcfc !important;
}

